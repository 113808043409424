import React, { useState, useEffect, Suspense } from 'react';
import './App.css';
import './Mobile.css';
import imageData from "./source_location.json";
import Loading from '../src/Components/Loading';
import DeviceRenderer from './Components/Paging_Render';
import MajorDetails from './Components/Major_Details';
import AQIIcon from './Components/AQI_icon';
import AQIDisplay from './Components/AQI_Display';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Mobile_AQI_Display from './Components/Mobile_Components/Mobile_AQI_Display';
import Mobile_Paging from './Components/Mobile_Components/Mobile_Paging';
import Mobile_Header from './Components/Mobile_Components/Mobile_Header';
import Mobile_Footer from './Components/Mobile_Components/Mobile_Footer';

const App = ({reqData}) => {

    const jsonData = {reqData}.reqData;
    const [isMobileComponent, setIsMobileComponent] = useState(false);
    const [places,setPlaces] = useState([]);
    const [index,setIndex] = useState(0);
    const [currTime, setCurrTime] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [data, setData] = useState([]);
    const tempvar = [];
    var last_time_all = {};
    const cpcb_url = jsonData.cpcb_url;
    const api_url = jsonData.url_list[0].api_url;
    const listURL = jsonData.url_list[0].list_url;
    var bps;
    var name_all = {};
    let last_time = 0;
    const param = jsonData.param;
    const thing_ids = jsonData.thing_ids;
    const params = jsonData.param_config;

    const options = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true 
    };    
    function time(){
        const now = new Date();
        const formattedTime = now.toLocaleString('en-US', options);
        setCurrentTime(formattedTime);
    }

    function updateTime() {
        var now = new Date();
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const formattedTime = now.toLocaleString('en-GB', options);
        setCurrTime(formattedTime);
    }

    function updateData(val){
        let params;
        if (val === -1 || typeof (val) === undefined) {
            params = {"temperature": NaN, "humidity": NaN, "pm2.5": NaN, "pm10": NaN, "co2": NaN, "co": NaN, "tvoc": NaN}
            return params
        }
        else {
            params = val.parameter_values;
        }
        if(jsonData.ospcb){
            const params_for_aqi = jsonData.params_for_aqi ? jsonData.params_for_aqi : ["PM2.5","PM10"];
            var oaqi = 0;
            params_for_aqi.forEach(p_name => {
                var _p_name = p_name
                if (p_name === "PM2.5") {
                    _p_name = "pm2.5"
                }
                else if (p_name === "PM10") {
                    _p_name = "pm10"
                }
                var temp = getSubIndex(p_name, params[_p_name]);
                oaqi = temp > oaqi ? temp : oaqi;
            });
            return oaqi;
        }

        // Data is not ospcb data.
        else{
            var aqi = 0;
            const params_for_aqi = jsonData.params_for_aqi ? jsonData.params_for_aqi : ["PM2.5","PM10"];
            params_for_aqi.forEach(p_name => {
                var _p_name = p_name
                if (p_name === "PM2.5") {
                    _p_name = "pm2.5"
                }
                else if (p_name === "PM10") {
                    _p_name = "pm10"
                }
                else if (p_name === "CO") {
                    _p_name = "co"
                }
                else if (p_name === "VOC") {
                    _p_name = "tvoc"
                }
                var temp = getSubIndex(p_name, params[_p_name]);
                // console.log("SI: " + p_name + " : " + temp + " : " + params[_p_name]);
                aqi = temp > aqi ? temp : aqi;
            });
            return Math.round(aqi);
        }
    }

    function updateAQI(val){
        if(val === -1 || typeof(val) === undefined || val === -1){
            console.log("Device Offline. Check Power and Network Connectivity");
            val = NaN
        }
        return val;
    }

    function updateOData(val){
        let oaqi = 0.00;
        let params = {};
        for (var i = 0; i < val.length; i++) {
            let tempIndex = parseFloat(val[i].pollutant_avg);
            params[val[i].pollutant_id] = getValfromSubIndex(val[i].pollutant_id, tempIndex);
            if (tempIndex > oaqi) {
                oaqi = tempIndex;
            }
        }
        if(typeof(param) !== undefined){
            var param_val = Math.round(params["PM2.5"])
            param_val = isNaN(param_val) ? "NA" : param_val
            return param_val;
        }
        else {
            if(isNaN(oaqi)){
                oaqi = -1;
            }
            return oaqi;
        }
    }

    function updateOAQI(val){

        if(val === -1){
            val = NaN;
            return;
        }
        return val;
    }

    if (typeof (jsonData.bps) === "undefined") {
        bps = {
            "aqi": [0, 50, 100, 200, 300, 400],
            "PM1": [0, 30, 60, 90, 120, 250],
            "PM2.5": [0, 30, 60, 90, 120, 250],
            "PM10": [0, 50, 100, 250, 350, 430],
            "CO2": [0, 600, 800, 1000, 2000, 5000],
            "TVOC": [0, 0.5, 1.0, 1.5, 2, 3],
            "CO": [0, 1, 2, 10, 17, 34],
            "NO2": [0, 40, 80, 180, 280, 400],
            "SO2": [0, 40, 80, 380, 800, 1600]
        }
    }
    else{
        bps = jsonData.bps;
    }

    function getSubIndex(param, val) {       
        if(param === 'VOC'){
            param = 'TVOC';
        }
        if (val > 0 && !['TVOC', 'CO2'].includes(param)) {
            let x = bps;
            for (let i = 0; i < 6; i++) {
                let ilow = x['aqi'][i];
                let ihigh = i === 5 ? 500 : x['aqi'][i + 1];
                let clow = x[param][i];
                let chigh = i === 5 ? (x[param][i] + x[param][5] - x[param][4]) : x[param][i + 1];
                if (val > clow && val <= chigh || i === 5) {
                    return ((val - clow) * ((ihigh - ilow) / (chigh - clow))) + ilow ;
                }
            }
        }
        return 0;
    }

    function getValfromSubIndex(param, subIndex){
        let x = bps;
        if (x[param] && subIndex) {
            for (var i = 0; i < 6; i++) {
                let ilow = x['aqi'][i];
                let ihigh = i === 5 ? 500 : x['aqi'][i + 1];
                let clow = x[param][i];
                let chigh = i === 5 ? (x[param][i] + x[param][5] - x[param][4]) : x[param][i + 1];
                if (subIndex > ilow && subIndex <= ihigh) {
                    return ((subIndex - ilow) * ((chigh - clow) / (ihigh - ilow))) + clow;
                }
            }
        }
        return NaN
    }

    function getParamColor(p_name, p_value) {
        if (p_value === 0 || p_value === undefined){
            return "#C9c9c5"
        }
        if (bps[p_name][0] < p_value && p_value <= bps[p_name][1]) {
            return '#00b050'
        }
        else if (bps[p_name][1] < p_value && p_value <= bps[p_name][2]) {
            return '#92d050'
        }
        else if (bps[p_name][2] < p_value && p_value <= bps[p_name][3]) {
            return '#E9D30E'
        }
        else if (bps[p_name][3] < p_value && p_value <= bps[p_name][4]) {
            return '#ed7d31'
        }
        else if (bps[p_name][4] < p_value && p_value <= bps[p_name][5]) {
            return '#ff0000'
        }
        else if (bps[p_name][5] < p_value) {
            return '#c00000'
        }
    }

    function calculateTime(time){

        const date = new Date(time * 1000);
        const formattedTime = date.toLocaleDateString('en-US', options);
        return formattedTime;

    }

    async function pollApi(thing_id){
        
        // Minor Details also should be retrieved and shown
        if(jsonData.adv){
            var now = Date.now();
            var payloadraw = {
                "data_type": "raw",
                "aggregation_period": 0,
                "parameters": params,
                "parameter_attributes": [],
                "things": [thing_id],
                "from_time": now / 1000 - jsonData.requestInterval,
                "upto_time": now / 1000
            };
            var reqOptionsRaw = {
                method: 'POST',
                body: JSON.stringify(payloadraw),
                headers: {
                    "Access-Id": jsonData.url_list[0].headers['Access-Id'],
                    "Access-Key": jsonData.url_list[0].headers['Access-Key'],
                    "Content-Type": "application/json"
                }
            };
            let jsonObject2;
            try{
                const res = await fetch(api_url, reqOptionsRaw);
                if(!res.ok){
                    console.log(`HTTP error! Status: ${res.status}`);
                }
                const new_data = await res.json();
                let x,y,aqi;

                // Data was not retrieved successfully.
                if(new_data.data[0] === undefined){
                    const prev_data = await getLastData(reqOptionsRaw, thing_id);
                    if(jsonData.param === undefined){
                        aqi = updateData(prev_data);
                    }
                    else if (prev_data !== -1 && prev_data.parameter_values['pm2.5'] !== undefined) {
                        aqi = prev_data.parameter_values['pm2.5']
                    }
                    else{
                        aqi = "NA";
                    }
                    if(prev_data === -1 || typeof(prev_data) === undefined){
                        x = aqi
                        y = "Device Offline. Check network and power Connectivity"
                    }
                    else{
                        x = prev_data.parameter_values;
                        y = prev_data.time;
                    }
                }

                // Data successfully recieved.
                else{
                    const temp = new_data.data.pop();
                    console.log("SUPER", temp);
                    
                    x = temp.parameter_values;
                    if(temp.time){
                        y = temp.time;
                    }
                    else{
                        y = "Device Offline. Check connection"
                    }
                    if(jsonData.param === undefined){
                        aqi = updateData(temp);
                    }
                    else{
                        aqi = x['pm2.5'];
                    }
                    last_time = 0;
                }
                y = calculateTime(y);
                const paramColors = {};
                for (const key in x) {
                    const capitalizedKey = key.toUpperCase();
                    if((key !== "temp" && key !== "humid") && (key !== 'temperature' && key !== 'humidity')){    // Because temp and humid are not defined in bps and do not have a color
                        const color = getParamColor(capitalizedKey, x[key]);
                        paramColors[capitalizedKey] = color;
                    }
                }
                paramColors['aqi'] = getParamColor('aqi', aqi);
                // This gets appended to the data variable everytime.
                jsonObject2 = {
                    "thing_id": thing_id,
                    "params": x,
                    "time": y,
                    "aqi":aqi,
                    "color": paramColors
                }
                localStorage.setItem('key', JSON.stringify(jsonObject2));
                // console.log("JUST THIS",JSON.parse(localStorage.getItem('key')));
                if(tempvar.length+1 > thing_ids.length){
                    const existingIndex = tempvar.findIndex(item => item.thing_id === jsonObject2.thing_id);
                    if(existingIndex !== -1){
                        Object.assign(tempvar[existingIndex], jsonObject2);
                    }
                }
                else{
                    tempvar.push(jsonObject2);
                }
                if(tempvar.length === thing_ids.length){
                    setData(tempvar)
                }
                else{
                    setData(prevData => [...prevData, tempvar[0]])
                }
            }
            catch(error){
                console.error("Error getting data: ",error);
            }
        }

        // Minor Details are not required
        if(!jsonData.adv){
            var now = Date.now();
            const headers = jsonData.url_list[0].headers;
            var payloadavg = {
                "data_type": "aggregate",
                "aggregation_period": 3600,
                "parameters": [
                    ((typeof (param) !== undefined) ? param.toLowerCase() : "aqi")
                ],
                "parameter_attributes": ["avg", "value"],
                "things": [thing_id],
                "from_time": now / 1000 - 3600,
                "upto_time": now / 1000
            };
            var reqOptionsAvg = {
                method: 'POST',
                body: JSON.stringify(payloadavg),
                headers: {
                    "Access-Id": headers['Access-Id'],
                    "Access-Key": headers['Access-Key'],
                    "Content-Type": "application/json"
                }
            }
            try{
                const res = await fetch(api_url, reqOptionsAvg);
                if(!res.ok){
                    console.log(`HTTP error! Status: ${res.status}`);
                }
                let jsonObject2,x;
                const new_data = await res.json();
                if(new_data.data[0] === undefined){
                    const prev_data = await getLastData(reqOptionsAvg,thing_id);
                    const test = updateAQI(prev_data);
                    let temp = {"aqi": test};
                    x = temp;
                }
                else{
                    x = new_data.data.pop().parameter_values;
                }
                const paramColors = {};
                if (x.aqi !== undefined && x.aqi.parameter_values !== undefined && x.aqi.parameter_values['pm2.5'] !== undefined && x.aqi.parameter_values['pm2.5'].avg !== undefined) {
                    var temp = x.aqi.parameter_values['pm2.5'].avg;
                }
                else if(isNaN(x.aqi)){
                    var temp = 'NA';
                }
                else{
                    var temp = x["pm2.5"].avg;
                }

                console.log('rewrwe', x);
                paramColors['pm2.5'] = getParamColor('PM2.5', temp);
                if(x.aqi !== undefined && x.aqi.parameter_values !== undefined && x.aqi.parameter_values['pm2.5'] !== undefined && x.aqi.parameter_values['pm2.5'].avg !== undefined){
                    x = x.aqi.parameter_values['pm2.5'].avg;
                }

                // This gets appended to the data everytime.
                jsonObject2 = {
                    "thing_id": thing_id,
                    "aqi": x,
                    "color": paramColors
                }
                localStorage.setItem('key', JSON.stringify(jsonObject2));
                // console.log("JUST THIS",JSON.parse(localStorage.getItem('key')));
                if(tempvar.length+1 > thing_ids.length){
                    const existingIndex = tempvar.findIndex(item => item.thing_id === jsonObject2.thing_id);
                    if(existingIndex !== -1){
                        Object.assign(tempvar[existingIndex], jsonObject2);
                    }
                }
                else{
                    tempvar.push(jsonObject2);
                }
                if(tempvar.length === thing_ids.length){
                    setData(tempvar)
                }
                else{
                    setData(prevData => [...prevData, tempvar[0]])
                }
            }
            catch(error){
                console.error("Error getting the data:", error);
            }
        }

        if(jsonData.comparisonMode === 1 || jsonData.comparisonMode === 2){
            const query_params = jsonData.cpcb_params;
            const url_headers = jsonData.headers_cpcb;
            const requestOptions = {
                method: 'GET',
                headers: {
                    'accept': url_headers.accept
                }
            };
            const queryString = Object.keys(query_params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query_params[key])}`)
            .join('&');
              
            // Append the query string to the base URL
            const urlWithParams = `${cpcb_url}?${queryString}`;
            let jsonObject2;
            try {
                const response = await fetch(urlWithParams,requestOptions);            
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                let x;
                const new_Data = await response.json();
                var tempObj = {}
                if(new_Data.records === undefined){
                    updateOAQI(Number(-1));
                }
                else{
                    let oaqi = updateOData(new_Data.records);
                    x = updateOAQI(oaqi);
                }
                const paramColors = {};
                for(let i=0;i<new_Data.records.length;i++){
                    if(new_Data.records[i].station === jsonData.cpcb_params['filters[station]']){
                        const key = new_Data.records[i].pollutant_id.toLowerCase();
                        if(jsonData.param_config.includes(key)){
                            tempObj[key] = new_Data.records[i].pollutant_avg;
                            const capitalizedKey = key.toUpperCase();
                            if((key !== "temp" && key !== "humid") && (key !== 'temperature' && key !== 'humidity') && (jsonData.param_config.includes(key))){
                                const color = getParamColor(capitalizedKey, new_Data.records[i].pollutant_avg);
                                paramColors[capitalizedKey] = color;
                            }
                        }
                        if(typeof(jsonData.param) !== undefined && new_Data.records[i].pollutant_id === 'PM2.5'){
                            paramColors[jsonData.param.toUpperCase()] = getParamColor('PM2.5', new_Data.records[i].pollutant_avg );
                        }
                    }
                }
                const aqiColor = getParamColor('aqi', x);
                paramColors['aqi'] = aqiColor;
                jsonObject2={
                    "params": tempObj,
                    "aqi": x,
                    "color": paramColors
                }
                localStorage.setItem('key', JSON.stringify(jsonObject2));
                // console.log("JUST THIS",JSON.parse(localStorage.getItem('key')));
                setData(prevData => [...prevData, jsonObject2])
            } 
            catch (error) {
                console.error('Error fetching data:', error.message);
            }
        }

        if(jsonData.comparisonMode === 3){
            var payloadraw = {
                "data_type": "raw",
                "aggregation_period": 0,
                "parameters": params,
                "parameter_attributes": [],
                "things": jsonData.othing_id,
                "from_time": now / 1000 - jsonData.requestInterval,
                "upto_time": now / 1000
            };
            var reqOptionsRaw = {
                method: 'POST',
                body: JSON.stringify(payloadraw),
                headers: {
                    "Access-Id": jsonData.oAccessId,
                    "Access-Key": jsonData.oAccessKey,
                    "Content-Type": "application/json"
                }
            };
            let jsonObject2;
            try{
                const response = await fetch(jsonData.oapi_url,reqOptionsRaw); 
                if(!response.ok){
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                let x;
                var tempObj = {};
                var paramColors = {};

                const date = new Date();

                // Format the date part
                const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
                const formattedDate = date.toLocaleDateString('en-US', optionsDate);

                // Format the time part
                const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
                const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
                var time = `${formattedDate} at ${formattedTime}`;
                // PM10: '#00b050', PM2.5: '#00b050', TVOC: '#FFFF00', aqi: '#00b050'
                const aqiColor = getParamColor('aqi', x);
                paramColors['aqi'] = aqiColor;
                paramColors['PM10'] = '#00b050';
                paramColors['PM2.5'] = '#00b050';
                paramColors['TVOC'] = '#00b050';
                const new_Data = await response.json();
                if (new_Data.data.length === 0) {
                    tempObj = {"pm2.5":"NA", "pm10":"NA", "tvoc":"NA", "temp":"NA", "humid":"NA"}
                    jsonObject2={
                        "thing_id": jsonData.othing_id[0],
                        "params": tempObj,
                        "time": time,
                        "aqi":x,
                        "color": paramColors
                    }
                }
                else{
                    const tempvar = new_Data.data[0];
                    if(jsonData.param === undefined){

                        x = updateData(tempvar);

                    }
                    else{

                        x = tempvar.parameter_values[jsonData.param.toLowerCase()];

                    }
                    // x = tempvar.parameter_values['pm2.5']
                    for (const key in tempvar.parameter_values) {
                        if (tempvar.parameter_values.hasOwnProperty(key)) {
                            let a,b;
                            a = key.toUpperCase();
                            b = tempvar.parameter_values[key];
                            if(key !== 'humid' && key !== 'temp' && key !== 'temperature' && key !== 'humidity'){
                                const tempColor = getParamColor(a, b);
                                paramColors[`${a}`] = tempColor;
                            }
                        }
                    }
                    if(jsonData.param !== undefined){
                        let key = jsonData.param.toLowerCase();
                        paramColors['aqi'] = getParamColor(jsonData.param, tempvar.parameter_values[key]);
                    }
                    else{
                        paramColors['aqi'] = getParamColor('aqi', x);
                    }
                    
                    // paramColors['aqi'] = getParamColor('AQI', x);
                    jsonObject2={
                        "thing_id": tempvar.thing_id,
                        "params": tempvar.parameter_values,
                        "time": time,
                        "aqi": x,
                        "color": paramColors
                    }
                    
                }
                localStorage.setItem('data', JSON.stringify(jsonObject2));
                setData(prevData => [...prevData, jsonObject2])
            }
            catch(error){
                console.error('Error fetching data:', error.message);
            }
        }
    }

    async function getThingsInfo(){

        const url_headers = jsonData.url_list[0].headers;
        const requestOptions = {
            method: 'GET',
            headers: {
                "Access-Id": url_headers['Access-Id'],
                "Access-Key": url_headers['Access-Key'],
                "Content-Type": "application/json"
            }
        };
        try{

            const response = await fetch(listURL, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            let jsonObject;
            const new_data = await response.json();
            if(new_data === undefined){
                var prev_data = Number(-1);
            }
            else{
                var things_list = new_data.things;
                things_list.forEach((thing, index)=> {
                    thing_ids.forEach((t_id) => {
                        if (thing.id === t_id) {
                            last_time_all[t_id] = thing.last_data_received_time;
                            name_all[t_id] = thing.name;
                            jsonObject = {
                                "thing_id" : t_id,
                                "place":thing.name
                            };
                            const existingIndex = places.findIndex(item => item.thing_id === t_id);
                            if (existingIndex !== -1) {
                                places[existingIndex] = jsonObject;
                              } else {
                                // Otherwise, push the new object into the places array
                                places.push(jsonObject);
                              }
                        }
                    });
                })
            }
            pollAll();
        }
        catch(error){
            console.error('Error getting data:', error);
        }
    }

    async function getLastData(reqOptions, t_id){
        let last_data;
        last_time = (typeof (last_time_all[t_id]) !== "undefined") ? last_time_all[t_id] : 0;
        if(last_time !== 0 && last_time != null){
            var options_json = JSON.parse(reqOptions.body);
            options_json.from_time = last_time - 7200;
            options_json.upto_time = last_time;
            reqOptions.body = JSON.stringify(options_json);
            try{

                const res = await fetch(api_url, reqOptions);
                if(!res.ok){
                    console.log(`HTTP error! Status: ${res.status}`);
                }
                const new_data = await res.json();
                if (new_data.data[0] === undefined) {
                    last_data = Number(-1);
                }
                else {
                    last_data = new_data.data.pop();
                }

            }
            catch(error){
                console.error("Error getting data: ", error);
            }
        }
        return last_data;
    }  

    async function pollAll(){
        thing_ids.forEach(thing_id => {
            if(thing_id !== null){
                pollApi(thing_id)
            }
        })
    }
    const incrementIndex = () => {
        setIndex(prevIndex => {return (prevIndex + 1)%thing_ids.length;});
    }

    useEffect(() => {
        getThingsInfo();
        setInterval(pollAll, jsonData.pollingInterval * 1000);
        updateTime();
        time();
        setInterval(updateTime, 60*1000);
        if(jsonData.paging){
            setInterval(incrementIndex, jsonData.pagingInterval * 1000)
        }
        const handleResize = () => {
            setIsMobileComponent(window.innerWidth < 912);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
    },[]);
    return (
        <>
            {(isMobileComponent) ? (
                <Suspense fallback={<Loading />}>
                    <div id='mobile-wrapper'>
                        <Mobile_Header key={"mobile-header"} props={jsonData} />
                        {(jsonData.paging) && (data.length === thing_ids.length) && Mobile_Paging(data, jsonData, index, places)}
                        {(!jsonData.paging) && 
                            (
                                <div id='mobile-root'>
                                {data.map((item,index) => (
                                    <div id='mobile-container'>
                                        <div id='mobile-top-container'>
                                            <div id='rectangle'>
                                                <div id='mobile-place-temp-humid-container'>
                                                    <div id='mobile-place-container'>
                                                    {places.map((place, i) => (
                                                        <div key={i} id='mobile-heading-container'>
                                                            {(!jsonData.ospcb) && (i === index) && (
                                                                <div>
                                                                    <img src='/img/Indoor_mobile.png' alt='Indoor Mobile' style={{zIndex: '-1'}} />
                                                                    <p>{place.place}</p>
                                                                </div>
                                                            )}
                                                            {(jsonData.ospcb) && (i === index) && (
                                                                <div>
                                                                    <img src='/img/Indoor_mobile.png' alt='Indoor Mobile' />
                                                                    <p>{place.place}</p>
                                                                </div>
                                                            )}
                                                            {(jsonData.ospcb) && (i !== index) && (jsonData.comparisonMode !== 3) && (
                                                                <div>
                                                                    <img src='/img/Outdoor_mobile.png' alt='Indoor Mobile' />
                                                                    <p>{jsonData.outdoorLocation!==""?jsonData.outdoorLocation:jsonData.cpcb_params["filters[station]"]}</p>
                                                                </div>
                                                            )}
                                                            {(jsonData.ospcb) && (i !== index) && (jsonData.comparisonMode === 3) && (
                                                                <div>
                                                                    <img src='/img/Indoor_mobile.png' alt='Indoor Mobile' style={{zIndex: '-1'}} />
                                                                    <p>{jsonData.outdoorLocation}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                    </div>
                                                    { (item.params) && (item.params.temp !== undefined || item.params.temperature !== undefined) && (item.params.humid || item.params.humidity) && (
                                                        <>
                                                            <div id='mobile-temp-container'>
                                                                <img src="/img/mobile-temperature.svg" alt='Temperature' id='mobile-temp-icon'></img>
                                                                {(data[index].params !== undefined) ? 
                                                                    (data[index].params.temp !== undefined ? 
                                                                        (<div id='mobile-temp'>{Math.round(data[index].params.temp)} <span id='Celcius' style={{fontSize: '11px'}}>&deg;C</span> </div>) :
                                                                        (data[index].params.temperature !== undefined ? 
                                                                            (<div id='mobile-temp'>{Math.round(data[index].params.temperature)} <span id='Celcius' style={{fontSize: '11px'}}>&deg;C</span></div>) :
                                                                            (<div id='mobile-temp'>NA</div>)
                                                                        )
                                                                    )
                                                                    :
                                                                    (<div id='mobile-temp'>NA</div>)
                                                                }
                                                            </div>
                                                            <div id='mobile-humid-container'>
                                                                <img src="/img/mobile-humidity.svg" alt='Humidity' id='mobile-humid-icon'></img>
                                                                {(data[index].params !== undefined) ? 
                                                                    (data[index].params.humid !== undefined ? 
                                                                        (<div id='mobile-humid'>{Math.round(data[index].params.humid)} %</div>) :
                                                                        (data[index].params.humidity !== undefined ? 
                                                                            (<div id='mobile-humid'>{Math.round(data[index].params.humidity)} %</div>) :
                                                                            (<div id='mobile-humid'>NA</div>)
                                                                        )
                                                                    )
                                                                    :
                                                                    (<div id='mobile-humid'>NA</div>)
                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div id='mobile-aqi-container'><Mobile_AQI_Display data={item} color={item.color} aqi={jsonData.param}/></div>
                                            {((item.aqi === undefined || item.aqi === 0 || isNaN(item.aqi)) && <img src='/img/none-aqi-image.png ' alt='No AQI Available' id='mobile-aqi-condition-image'/>)}
                                            {(item.aqi > 0 && item.aqi <= 50 && <img src='/img/good-aqi-image.png' alt='Good' id='mobile-aqi-condition-image'/>)}
                                            {(item.aqi > 50 && item.aqi <= 100 && <img src='/img/satisfactory-aqi-image.png' alt='Satisfactory' id='mobile-aqi-condition-image'/>)}
                                            {(item.aqi > 100 && item.aqi <= 200 && <img src='/img/moderate-aqi-image.png' alt='Moderate' id='mobile-aqi-condition-image'/>)}
                                            {(item.aqi > 200 && item.aqi <= 300 && <img src='/img/poor-aqi-image.png' alt='Poor' id='mobile-aqi-condition-image'/>)}
                                            {(item.aqi > 300 && item.aqi <= 400 && <img src='/img/verypoor-aqi-image.png' alt='Very Poor' id='mobile-aqi-condition-image'/>)}
                                            {(item.aqi > 400 && item.aqi <= 500 && <img src='/img/severe-aqi-image.png' alt='Severe' id='mobile-aqi-condition-image'/>)}
                                            {(item.aqi > 500 && <img src='/img/severe-aqi-image.png' alt='Severe' id='mobile-aqi-condition-image'/>)}
                                        </div>
                                        <div id='mobile-bottom-container'>
                                            {params && jsonData.adv && (
                                                <div id='mobile-minor-details'>
                                                    {jsonData.param_config.map((type, i) => {
                                                            const value = item.params[type];
                                                            var formattedValue;
                                                            if (value === undefined || value === null || isNaN(Number(value))) {
                                                                formattedValue = "NA";
                                                            }
                                                            else{
                                                                formattedValue = Number(value).toFixed(2);
                                                            }
                                                            if(type !== 'temp' && type !== 'humid' && type !== 'temperature' && type !== 'humidity' && !(type === 'pm2.5' && jsonData.param !== undefined)){
                                                                return(
                                                                    <div id='mobile-data-container' key={i} >
                                                                        <p id="mobile-type">{type.toUpperCase()}</p>
                                                                        <p id="mobile-value" style={{color: `${item.color[type.toUpperCase()]}`}}>{formattedValue}</p>
                                                                        <p id="mobile-unit">{imageData.units[type]}</p>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                </div>
                            )
                        }
                        <Mobile_Footer props={jsonData} />
                    </div>
                </Suspense>
            ) : (
                <>
                    <Suspense fallback={<Loading />}>
                        <div className='wrapper'>
                            <Header data={jsonData} time={currTime}/>
                            {jsonData.paging && data.length === thing_ids.length && DeviceRenderer(data,index,places,jsonData)}
                            {(thing_ids.length > 2) && (!jsonData.paging) && (
                                <div className='root'>
                                    {data.map((item, index) => (
                                        <div className='parent'>
                                            {places
                                                .filter(place => place.thing_id === data[index].thing_id)
                                                .map((place, i) => (
                                                    <div key={i} className='heading-container'>
                                                        <h2 className='heading'>{place.place}</h2>
                                                    </div>
                                            ))}
                                            <MajorDetails jsonData={jsonData} data={data} index={index} />
                                            {params && jsonData.adv &&(
                                                <div className='Minor_Details'>
                                                    {jsonData.param_config.map((type, i) => {
                                                        const value = parseFloat(item.params[type]);
                                                        const formattedValue = value ? (value.toFixed(2)) : "NA";
                                                        if(type !== 'temp' && type !== 'humid' && type !== 'temperature' && type !== 'humidity' && !(type === 'pm2.5' && jsonData.param !== undefined)){
                                                            return (
                                                                <div className="pm25_container" id="pm25_container">
                                                                    <div className="left-hand" id="left-hand">
                                                                        <img src={imageData.images[type]} id="pm25_img" alt='Minor Icon' />
                                                                        <p className="type">{type.toUpperCase()}</p>
                                                                    </div>
                                                                    <div className="right-hand" id="right-hand">
                                                                        <p className="val" style={{ color: item.color[type.toUpperCase()] }}>{formattedValue}</p>
                                                                        <p className="unit">{imageData.units[type]}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            )}
                                            {
                                                <div id='time'>Last Updated : {item.time.replace(/(AM|PM)/g, function(match) { return match.toLowerCase(); })}</div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            )}
                            {(thing_ids.length <= 2) && (!jsonData.paging) && (
                                <div className='root new-root'>
                                    {data.map((item, index) => (
                                        <div className='new-parent'>
                                            {places.map((place, i) => (
                                                <div key={i} id='new-heading-container'>                                                    
                                                    {(!(jsonData.comparisonMode === 2)) && (i === index) && (
                                                        <div>
                                                            {(jsonData.comparisonMode === 3) && (
                                                                <div id='location-container'>
                                                                    <img id='indoor' alt='Indoor' src={jsonData.indoorIcon} />
                                                                    <p>INDOOR</p>
                                                                </div>
                                                            )}
                                                            {(place.place !== undefined) && (
                                                                <h2>{place.place}</h2>
                                                            )}
                                                        </div>
                                                    )}
                                                    {(jsonData.comparisonMode === 2) && (i === index) && (
                                                        <>
                                                            {(jsonData.comparisonMode === 2 || jsonData.comparisonMode === 3) && (
                                                                <div id='location-container'>
                                                                    <img id='indoor' alt='Indoor' src={jsonData.indoorIcon} />
                                                                    <p>INDOOR</p>
                                                                </div>
                                                            )}
                                                            <h2>{place.place}</h2>
                                                        </>
                                                    )}
                                                    {(jsonData.comparisonMode === 2 || jsonData.comparisonMode === 3) && (i !== index) && (
                                                        <>
                                                            {(jsonData.comparisonMode === 2 || jsonData.comparisonMode === 3) && (
                                                                <div id='location-container'>
                                                                    <img id='outdoor' alt='Outdoor' src='/img/outdoor.png' />
                                                                    <p>OUTDOOR</p>
                                                                </div>
                                                            )}
                                                            <h2>{jsonData.outdoorLocation!==""?jsonData.outdoorLocation:jsonData.cpcb_params["filters[station]"]}</h2>
                                                        </>
                                                    )}
                                                    {/* {(jsonData.ospcb) && (i !== index) && (jsonData.comparisonMode === 3) && (
                                                        <div>
                                                            <p>{jsonData.outdoorLocation}</p>
                                                        </div>
                                                    )} */}
                                                </div>
                                            ))}
                                            <div className='new-Major-Details'>
                                                {jsonData.adv && (data[index].params.temp !== undefined || data[index].params.temperature !== undefined) && (data[index].params.humid || data[index].params.humidity) ? 
                                                (
                                                    <div id='new-temp-humid-display'>
                                                        <div id='new-temp-display'>
                                                            <img src="/img/temp.png" alt='Temperature' id='new-temp-icon'></img>
                                                            {(data[index].params !== undefined) ? 
                                                                (data[index].params.temp !== undefined ? 
                                                                    (<div id='new-temp'>{Math.round(data[index].params.temp)} &deg;C</div>) :
                                                                    (data[index].params.temperature !== undefined ? 
                                                                        (<div id='new-temp'>{Math.round(data[index].params.temperature)} &deg;C</div>) :
                                                                        (<div id='new-temp'>NA</div>)
                                                                    )
                                                                )
                                                                :
                                                                (<div id='new-temp'>NA</div>)
                                                            }
                                                        </div>
                                                        <div id='new-humid-display'>
                                                            <img src="/img/humid.png" alt='Humidity' id='new-humid-icon'></img>
                                                            {(data[index].params !== undefined) ? 
                                                                (data[index].params.humid !== undefined ? 
                                                                    (<div id='new-temp'>{Math.round(data[index].params.humid)} %</div>) :
                                                                    (data[index].params.humidity !== undefined ? 
                                                                        (<div id='new-temp'>{Math.round(data[index].params.humidity)} %</div>) :
                                                                        (<div id='new-temp'>NA</div>)
                                                                    )
                                                                )
                                                                :
                                                                (<div id='new-temp'>NA</div>)
                                                            }
                                                        </div>
                                                    </div>
                                                ) 
                                                :null
                                                }
                                                <div id='new-aqi-res-display'>
                                                    <div id='new-aqi-display'>
                                                        <AQIIcon val={data[index]} jsonData={jsonData}/>
                                                    </div>
                                                    <div className='Result' id='test-res'>
                                                        <AQIDisplay data={item} /> 
                                                    </div>
                                                </div>
                                            </div>
                                            {params && jsonData.adv &&(
                                                <div className='new-Minor-Details'>
                                                    {jsonData.param_config.map((type, i) => {
                                                        const value = item.params[type];
                                                        var formattedValue;
                                                        if (value === undefined || value === null || isNaN(Number(value))) {
                                                            formattedValue = "NA";
                                                        }
                                                        else{
                                                            formattedValue = Number(value).toFixed(2);
                                                        }
                                                        if(type !== 'temp' && type !== 'humid' && type !== 'temperature' && type !== 'humidity' && !(type === 'pm2.5' && jsonData.param !== undefined)){
                                                            return (
                                                                <div id="new-pm25-container" key={i} style={{borderBottom: `4px solid ${item.color[type.toUpperCase()]}`}}>
                                                                    <div id="top">
                                                                        <img src={imageData.images[type]} id="new-pm25-image" alt='new pm25'/>
                                                                        <p className="new-value">{formattedValue}</p>
                                                                    </div>
                                                                    <div id="bottom">
                                                                        <p className="new-type">{type.toUpperCase()}</p>
                                                                        <p className="new-unit">{imageData.units[type]}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            )}
                                            {thing_ids[0] !== 13328 && (
                                                item.time ? 
                                                    (
                                                        <div id='time'>Last Updated : {item.time}</div>
                                                    )
                                                    :(
                                                        <div id='time'>Last Updated : {currentTime}</div>
                                                    )
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <Footer props={jsonData} />
                        </div>
                    </Suspense>
                </>
            )}  
        </>
    );
};

export default App;